<template>
  <v-container class="container-full-size">
    <v-card color="basil" style="margin-top: 0" flat>
      <div v-if="excludeExercises || showUserNotes" class="client-user-notes">
        <client-notes ref="notes" :client="client" mode="training" />
      </div>
      <div v-if="showLastFeedbackPhotos" class="client-user-photos">
        <client-photos :client="client" />
      </div>
      <div v-if="client.showInternalNotes" class="client-pt-notes">
        <div>
          <div>
            <v-textarea v-model="notesTrainingPlan" :label="str['training_plan_notes']" :rows="3" hide-details />
          </div>
        </div>
      </div>
      <v-col cols="12" class="text-right">
        <v-btn v-if="trainingPlansSorted" color="warning" class="mr-4" @click="saveSortTrainingPlans()">
          {{ str['save_changes'] }}
        </v-btn>
        <v-btn v-if="client.showInternalNotes" color="secondary" class="mr-4" @click="client.saveInternalNotes()">
          {{ str['save_notes'] }}
        </v-btn>
        <v-btn v-if="showTrainingPlanProgressButton" color="blue" class="mr-4" @click="showTrainingPlanProgress()">
          {{ showTrainingPlanWeeksProgressButton ? str['progress_exercises'] : str['progress'] }}
        </v-btn>
        <v-btn v-if="showTrainingPlanWeeksProgressButton" color="blue" class="mr-4" @click="showTrainingPlanWeeksProgress()">
          {{ showTrainingPlanProgressButton ? str['progress_plans'] : str['progress'] }}
        </v-btn>
        <v-btn v-if="hasPermission('trng_plans_edit')" color="success" class="mr-0" @click="addTrainingPlan()">
          {{ str['add'] }}
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-card-text v-if="!trainingPlans.length && isLoading" class="text-center" style="padding: 40px 0">
          {{ str['processing'] }}
        </v-card-text>
        <v-card-text v-if="!trainingPlans.length && !isLoading" class="text-center" style="padding: 40px 0">
          {{ str['training_plans_empty'] }}
        </v-card-text>
        <v-list one-line style="min-height: 250px; padding: 0">
          <draggable class="sortable-list-group" handle=".handle" @start="dragging = true" @end="sortTrainingPlans">
            <div v-for="trainingPlan in trainingPlans" :key="trainingPlan.id" :data-id="trainingPlan.id">
              <v-list-item @click="openTrainingPlan(trainingPlan)">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ trainingPlan.name }}
                    <span>
                      -
                      <span :class="client.getPlanStatus(trainingPlan, 'training_plans', trainingPlans).type + '--text'">
                        {{ client.getPlanStatus(trainingPlan, 'training_plans', trainingPlans).label
                        }}{{ showTotalTrainsCount ? ' (' + trainingPlan.days_count + ' ' + str['trains'] + ')' : '' }}
                      </span>
                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle
                    v-if="
                      user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.show_dates
                    "
                  >
                    {{ trainingPlan.start_date + ' / ' + trainingPlan.end_date }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-if="
                      user &&
                      user.configurations &&
                      user.configurations.training_plans &&
                      !user.configurations.training_plans.show_dates &&
                      trainingPlan.status !== 1
                    "
                  >
                    {{ trainingPlan.start_date + ' / ' + trainingPlan.end_date }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-if="
                      user &&
                      user.configurations &&
                      user.configurations.training_plans &&
                      !user.configurations.training_plans.show_dates &&
                      trainingPlan.status === 1
                    "
                  >
                    {{ str['started_in'] }} {{ trainingPlan.start_date }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <div v-if="hasPermission('trng_plans_edit')" class="row-flex-align">
                    <div>
                      <v-icon small class="mr-0" color="teal lighten-2" @click.stop="duplicateTrainingPlan(trainingPlan)">
                        mdi-content-duplicate
                      </v-icon>
                    </div>
                    <div v-if="showProgressButtons" class="row-flex-col-align-center list-item-actions pl-4">
                      <v-btn
                        v-if="planStatusDict[trainingPlan.status] !== 'active'"
                        color="warning"
                        class="mr-0"
                        outlined
                        small
                        @click.stop="changePlanStatus(trainingPlan, 'active')"
                      >
                        {{ str['activate'] }}
                      </v-btn>
                      <v-btn
                        v-if="planStatusDict[trainingPlan.status] !== 'finished'"
                        color="success"
                        class="mr-0"
                        outlined
                        small
                        @click.stop="changePlanStatus(trainingPlan, 'finished')"
                      >
                        {{ str['finish'] }}
                      </v-btn>
                    </div>
                    <div v-if="showSortTrainingPlans">
                      <v-icon color="default" class="handle cursor-move" style="margin-left: 10px">mdi-format-align-justify</v-icon>
                    </div>
                  </div>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
            </div>
          </draggable>
        </v-list>
      </v-col>
    </v-card>

    <v-dialog v-model="dialogNewTrainingPlan" scrollable persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['training_plans_base'] }}
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px; padding: 10px 0">
          <v-col cols="12" class="pt-0" style="padding-bottom: 20px; margin-bottom: 10px; border-bottom: 1px solid #ddd">
            <v-row>
              <v-col :cols="12" class="pt-0 pb-0">
                <v-text-field
                  v-model="baseTrainingPlansSearchValue"
                  append-icon="mdi-magnify"
                  :label="str['search']"
                  single-line
                  hide-details
                />
              </v-col>
              <v-col v-if="showPlaceAndGender && trngPlanPlaces && trngPlanPlaces.length" :cols="showLevel ? 4 : 6">
                <v-autocomplete
                  v-model="baseTrainingPlansFilters.place"
                  :label="str['place']"
                  item-text="label"
                  item-value="value"
                  :items="trngPlanPlaces"
                  :no-data-text="str['no_data']"
                  clearable
                  hide-details
                />
              </v-col>
              <v-col v-if="showPlaceAndGender && trngPlanGenders && trngPlanGenders.length" :cols="showLevel ? 4 : 6">
                <v-autocomplete
                  v-model="baseTrainingPlansFilters.gender"
                  :label="str['gender']"
                  item-text="label"
                  item-value="value"
                  :items="trngPlanGenders"
                  :no-data-text="str['no_data']"
                  clearable
                  hide-details
                />
              </v-col>
              <v-col v-if="showLevel" :cols="showPlaceAndGender ? 4 : 6">
                <v-autocomplete
                  v-model="baseTrainingPlansFilters.level"
                  :label="str['level']"
                  item-text="label"
                  item-value="value"
                  :items="trngPlanLevels"
                  :no-data-text="str['no_data']"
                  clearable
                  hide-details
                />
              </v-col>
              <v-col v-if="showFilterTotalTrains" :cols="6" class="pt-0">
                <v-autocomplete
                  v-model="baseTrainingPlansFilters.total_trains"
                  :label="str['total_trains']"
                  item-text="label"
                  item-value="value"
                  :items="trngPlanTotalTrains"
                  :no-data-text="str['no_data']"
                  clearable
                  hide-details
                />
              </v-col>
              <v-col v-if="showDuration" :cols="6" class="pt-0">
                <v-autocomplete
                  v-model="baseTrainingPlansFilters.duration"
                  :label="str['duration']"
                  item-text="label"
                  item-value="value"
                  :items="trngPlanDurations"
                  :no-data-text="str['no_data']"
                  clearable
                  hide-details
                />
              </v-col>
              <v-col v-if="hasPdfTrainingPlan" :cols="showPlaceAndGender ? 4 : 6">
                <v-autocomplete
                  v-model="baseTrainingPlansFilters.type"
                  :label="str['type']"
                  item-text="label"
                  item-value="value"
                  :items="trngPlanTypes"
                  :no-data-text="str['no_data']"
                  clearable
                  hide-details
                />
              </v-col>
            </v-row>
          </v-col>
          <div v-if="!baseTrainingPlansFiltered.length" style="text-align: center; color: #888; padding: 40px 0">
            {{ str['no_training_plans_with_filter'] }}
          </div>
          <div
            v-for="baseTrainingPlan in baseTrainingPlansFiltered"
            :key="baseTrainingPlan.id"
            class="box-select-item"
            :class="{ 'box-select-item-flex': showBaseTrainingPlanInfo }"
            @click="newTrainingPlan(baseTrainingPlan)"
          >
            <span>
              {{ baseTrainingPlan.name }}
              <span v-if="showBaseAddedLabel && baseTrainingPlansAdded.indexOf(baseTrainingPlan.id) > -1" class="warning--text">
                ({{ baseTrainingPlanCurrent === baseTrainingPlan.id ? str['added_current'] : str['added'] }})
              </span>
            </span>
            <v-icon
              v-if="showBaseTrainingPlanInfo"
              color="info"
              dark
              class="cursor-hover ml-1"
              @click.stop="openBaseTrainingPlanInfo(baseTrainingPlan)"
            >
              mdi-information
            </v-icon>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="display: flex; justify-content: center">
          <v-btn color="default" @click="dialogNewTrainingPlan = false">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn color="success" style="margin-right: 0" @click="newTrainingPlan()">
            {{ str['create_new'] }}
          </v-btn>
          <v-btn v-if="hasPdfTrainingPlan" color="success" style="margin-right: 0" @click="newTrainingPlan({ type: pdfTrainingPlanType })">
            {{ str['add_pdf'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="hasPdfTrainingPlan" v-model="dialogPdfTrainingPlan" persistent max-width="650px">
      <v-card v-if="pdfTrainingPlan">
        <v-card-title>
          <span class="headline">
            {{ str['training_plan'] }}
            {{ pdfTrainingPlan && pdfTrainingPlan.date ? '(' + pdfTrainingPlan.date + ')' : '' }}
          </span>
        </v-card-title>
        <div>
          <v-col cols="12" style="padding-bottom: 0">
            <v-text-field
              v-model="pdfTrainingPlan.name"
              name="input-7-1"
              :label="str['name']"
              class="purple-input"
              :readonly="!pdfTrainingPlan.current"
            />
          </v-col>
          <v-col cols="12" style="padding-bottom: 0">
            <div style="display: flex">
              <div style="width: 100%; margin-right: 10px">
                <v-menu
                  v-model="pdfTrainingPlanStartDateDatepicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field v-model="pdfTrainingPlan.start_date" :label="str['start_date']" readonly v-bind="attrs" v-on="on" />
                  </template>
                  <v-date-picker
                    v-model="pdfTrainingPlan.start_date"
                    style="margin: 0"
                    :locale="datepickerLanguage"
                    @change="pdfTrainingPlanStartDateDatepicker = false"
                  />
                </v-menu>
              </div>
              <div style="width: 100%; margin-left: 10px">
                <v-menu
                  v-model="pdfTrainingPlanEndDateDatepicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field v-model="pdfTrainingPlan.end_date" :label="str['end_date']" readonly v-bind="attrs" v-on="on" />
                  </template>
                  <v-date-picker
                    v-model="pdfTrainingPlan.end_date"
                    style="margin: 0"
                    :locale="datepickerLanguage"
                    @change="pdfTrainingPlanEndDateDatepicker = false"
                  />
                </v-menu>
              </div>
            </div>
          </v-col>
          <div style="padding: 20px; display: flex; justify-content: center; align-items: center">
            <v-btn color="blue" class="mr-0">
              {{ str['add_pdf'] }}
            </v-btn>
            <input
              id="input-pdf-value"
              type="file"
              accept="application/pdf,application/vnd.ms-excel"
              style="opacity: 0; position: absolute; height: 45px; width: 160px"
              @change="loadPdfTrainingPlanPdf"
            />
          </div>
          <div style="padding: 0 0 20px">
            <iframe v-if="pdfTrainingPlan && pdfTrainingPlan.details" :src="pdfTrainingPlan.details" style="width: 100%; height: 50vh" />
          </div>
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn color="default" @click="dialogPdfTrainingPlan = null">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn v-if="pdfTrainingPlan && pdfTrainingPlan.current" color="success" @click="savePdfTrainingPlan(true)">
            {{ str['save_with_historic'] }}
          </v-btn>
          <v-btn v-if="pdfTrainingPlan && pdfTrainingPlan.current" color="success" @click="savePdfTrainingPlan()">
            {{ str['save_without_historic'] }}
          </v-btn>
          <v-btn v-if="pdfTrainingPlan && !pdfTrainingPlan.current" color="success" @click="savePdfTrainingPlan()">
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="showBaseTrainingPlanInfo" v-model="dialogBaseTrainingPlanInfo" persistent max-width="650px">
      <v-card v-if="baseTrainingPlanInfo && baseTrainingPlanInfo.data">
        <v-card-title>
          <span class="headline">
            {{ baseTrainingPlanInfo.data.name ? baseTrainingPlanInfo.data.name : str['training_plan'] }}
          </span>
        </v-card-title>
        <div>
          <v-col cols="12" class="pb-0 base-item-info-container">
            <div v-if="baseTrainingPlanInfo.details" class="pb-2">
              <div v-if="baseTrainingPlanInfo.details.place">
                <b>{{ str['place'] }}:</b>
                {{ baseTrainingPlanInfo.details.place }}
              </div>
              <div v-if="baseTrainingPlanInfo.details.gender">
                <b>{{ str['gender'] }}:</b>
                {{ baseTrainingPlanInfo.details.gender }}
              </div>
              <div v-if="baseTrainingPlanInfo.details.level">
                <b>{{ str['level'] }}:</b>
                {{ baseTrainingPlanInfo.details.level }}
              </div>
            </div>
            <div>
              <div v-for="(dy, dyIndex) in baseTrainingPlanInfo.data.days" :key="dyIndex" class="base-item-info-container-box">
                <h4 :class="{ 'pb-1': dy.steps.length }">
                  {{ dy.name }}
                </h4>
                <div v-for="(sp, spIndex) in dy.steps" :key="spIndex" class="base-item-info-container-steps">
                  <div v-if="workoutsDict[sp.workout_id]">
                    {{ workoutsDict[sp.workout_id].name }}
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn color="default" @click="closeBaseTrainingPlanInfo">
            {{ str['back'] }}
          </v-btn>
          <v-btn color="success" style="margin-right: 0" @click="newTrainingPlan(baseTrainingPlanInfo.data)">
            {{ str['confirm'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'
import moment from 'moment'
import draggable from 'vuedraggable'

export default {
  name: 'ClientTrainingPlan',
  components: {
    draggable,
    ClientNotes: () => import('@/components/dashboard/client/Notes'),
    ClientPhotos: () => import('@/components/dashboard/client/Photos'),
  },
  props: {
    client: Object,
  },
  data() {
    const user = Utils.getUser()

    let showTrainingPlanProgressButton = false
    if (user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.show_progress) {
      showTrainingPlanProgressButton = true
    }

    let showTrainingPlanWeeksProgressButton = false
    if (
      user &&
      user.configurations &&
      user.configurations.training_plans &&
      (user.configurations.training_plans.show_weeks_progress ||
        user.configurations.training_plans.show_weeks_progress_table ||
        user.configurations.training_plans.show_weeks_progress_chart)
    ) {
      showTrainingPlanWeeksProgressButton = true
    }

    let showPlaceAndGender = false
    if (user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.show_place_gender) {
      showPlaceAndGender = true
    }

    let showLevel = false
    if (user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.show_level) {
      showLevel = true
    }

    let hasPdfTrainingPlan = false
    if (
      user &&
      user.configurations &&
      user.configurations.menu_items &&
      user.configurations.menu_items.indexOf('pdf_training_plans_base') > -1
    ) {
      hasPdfTrainingPlan = true
    }

    let showBaseTrainingPlanInfo = false
    if (user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.show_base_info) {
      showBaseTrainingPlanInfo = true
    }

    let excludeExercises = false
    if (
      user &&
      user.configurations &&
      user.configurations.training_plans &&
      user.configurations.training_plans.exclude_exercises &&
      Utils.hasPermission('user_notes_view')
    ) {
      excludeExercises = true
    }

    let showUserNotes = false
    if (
      user &&
      user.configurations &&
      user.configurations.training_plans &&
      user.configurations.training_plans.show_user_notes &&
      Utils.hasPermission('user_notes_view')
    ) {
      showUserNotes = true
    }

    let showBaseAddedLabel = false
    if (user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.show_base_added_label) {
      showBaseAddedLabel = true
    }

    let autoFilterGender = false
    if (user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.auto_filter_gender) {
      autoFilterGender = true
    }

    let autoFilters = false
    if (user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.auto_filters) {
      autoFilters = true
    }

    let showFilterTotalTrains = false
    if (user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.show_filter_total_trains) {
      showFilterTotalTrains = true
    }

    let showDuration = false
    if (user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.show_duration) {
      showDuration = true
    }

    let showLastFeedbackPhotos = false
    if (user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.show_last_feedback_photos) {
      showLastFeedbackPhotos = true
    }

    let showProgressButtons = false
    if (user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.show_progress_buttons) {
      showProgressButtons = true
    }

    let defaultStartDate = false
    if (user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.default_start_date) {
      defaultStartDate = user.configurations.training_plans.default_start_date
    }

    let defaultEndDate = false
    if (user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.default_end_date) {
      defaultEndDate = user.configurations.training_plans.default_end_date
    }

    let showSortTrainingPlans = false
    if (user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.show_sort) {
      showSortTrainingPlans = true
    }

    let hasTranslations = false
    if (user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.has_translations) {
      hasTranslations = user.configurations.training_plans.has_translations
    }

    let showTotalTrainsCount = false
    if (user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.show_total_trains_count) {
      showTotalTrainsCount = true
    }

    return {
      str: window.strings,
      datepickerLanguage: window.datepickerLanguage,
      hasPermission: Utils.hasPermission,
      user: user,
      isLoading: false,
      showTrainingPlanProgressButton: showTrainingPlanProgressButton,
      showTrainingPlanWeeksProgressButton: showTrainingPlanWeeksProgressButton,
      showPlaceAndGender: showPlaceAndGender,
      showLevel: showLevel,
      hasPdfTrainingPlan: hasPdfTrainingPlan,
      showBaseTrainingPlanInfo: showBaseTrainingPlanInfo,
      excludeExercises: excludeExercises,
      showUserNotes: showUserNotes,
      showBaseAddedLabel: showBaseAddedLabel,
      autoFilterGender: autoFilterGender,
      autoFilters: autoFilters,
      showFilterTotalTrains: showFilterTotalTrains,
      showDuration: showDuration,
      showLastFeedbackPhotos: showLastFeedbackPhotos,
      showProgressButtons: showProgressButtons,
      defaultStartDate: defaultStartDate,
      defaultEndDate: defaultEndDate,
      showSortTrainingPlans: showSortTrainingPlans,
      hasTranslations: hasTranslations,
      showTotalTrainsCount: showTotalTrainsCount,
      baseTrainingPlansFilters: {
        place: null,
        gender: null,
        level: null,
        total_trains: null,
        duration: null,
        type: null,
      },
      trngPlanPlaces: Utils.getTrngPlanPlaces(),
      trngPlanGenders: Utils.getTrngPlanGenders(),
      trngPlanLevels: Utils.getTrngPlanLevels(),
      trngPlanTotalTrains: Utils.getTrngPlanTotalTrains(),
      trngPlanDurations: Utils.getTrngPlanDurations(),
      trngPlanTypes: Utils.getTrngPlanTypes(),
      trainingPlans: [],
      baseTrainingPlans: [],
      baseTrainingPlansAdded: [],
      baseTrainingPlanCurrent: null,
      baseTrainingPlansSearchValue: '',
      dialogNewTrainingPlan: false,
      pdfTrainingPlanType: 1,
      dialogPdfTrainingPlan: false,
      pdfTrainingPlan: null,
      pdfTrainingPlanStartDateDatepicker: null,
      pdfTrainingPlanEndDateDatepicker: null,
      dialogBaseTrainingPlanInfo: null,
      baseTrainingPlanInfo: null,
      workoutsDict: null,
      planStatusDict: this.getPlanStatusDict(),
      dragging: false,
      trainingPlansSorted: null,
    }
  },
  computed: {
    baseTrainingPlansFiltered: function () {
      const self = this
      let baseTrainingPlans = this.baseTrainingPlans
      if (this.baseTrainingPlansSearchValue) {
        const searchValue = this.baseTrainingPlansSearchValue.toLowerCase()
        baseTrainingPlans = baseTrainingPlans.filter(function (item) {
          return item.name.toLowerCase().indexOf(searchValue) > -1
        })
      }
      if (this.showPlaceAndGender) {
        if (this.baseTrainingPlansFilters.place) {
          baseTrainingPlans = baseTrainingPlans.filter(function (item) {
            return item.place === self.baseTrainingPlansFilters.place
          })
        }
        if (this.baseTrainingPlansFilters.gender) {
          baseTrainingPlans = baseTrainingPlans.filter(function (item) {
            return item.gender === self.baseTrainingPlansFilters.gender
          })
        }
      }
      if (this.showLevel && this.baseTrainingPlansFilters.level) {
        baseTrainingPlans = baseTrainingPlans.filter(function (item) {
          return item.level === self.baseTrainingPlansFilters.level
        })
      }
      if (this.showFilterTotalTrains && this.baseTrainingPlansFilters.total_trains) {
        baseTrainingPlans = baseTrainingPlans.filter(function (item) {
          return item.days_count === parseInt(self.baseTrainingPlansFilters.total_trains)
        })
      }
      if (this.showDuration && this.baseTrainingPlansFilters.duration) {
        baseTrainingPlans = baseTrainingPlans.filter(function (item) {
          return item.duration === self.baseTrainingPlansFilters.duration
        })
      }
      if (this.hasPdfTrainingPlan && (this.baseTrainingPlansFilters.type || this.baseTrainingPlansFilters.type === 0)) {
        baseTrainingPlans = baseTrainingPlans.filter(function (item) {
          return item.type === self.baseTrainingPlansFilters.type
        })
      }
      return baseTrainingPlans.sort((a, b) => a.name.localeCompare(b.name))
    },
    notesTrainingPlan: {
      get() {
        return this.client.internalNotes.training_plan
      },
      set(value) {
        this.client.setInternalNotes('training_plan', value)
      },
    },
  },
  beforeMount: function () {
    if (!this.user) {
      return false
    }
    this.getTrainingPlans()
  },
  methods: {
    getPlanStatusDict: function () {
      const statusItems = Utils.getPlanStatus()
      const statusDict = {}
      if (statusItems && statusItems.length) {
        statusItems.forEach(function (it) {
          statusDict[it.value] = it.status
        })
      }
      return statusDict
    },
    getTrainingPlans: function (callback) {
      const self = this
      this.isLoading = true
      Api.getTrainingPlans(
        {
          client_id: this.client.dbId,
          fast: true,
          sort_order: this.showSortTrainingPlans,
          fields: [
            'days_count',
            'end_date',
            'gender',
            'id',
            'level',
            'name',
            'place',
            'proposal',
            'start_date',
            'status',
            'type',
            'base_id',
          ],
        },
        function (response) {
          self.isLoading = false
          if (response.success) {
            self.trainingPlans = response.data
          }
          if (callback) {
            callback()
          }
        },
      )
    },
    showTrainingPlanProgress: function () {
      this.$router.navigate.push({ path: '/home/trainingPlanProgress' })
    },
    showTrainingPlanWeeksProgress: function () {
      this.$router.navigate.push({ path: '/home/trainingPlanWeeksProgress' })
    },
    openTrainingPlan: function (item) {
      const self = this
      this.$isLoading(true)
      Api.getTrainingPlan(
        {
          client_id: this.client.dbId,
          id: item.id,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            const src = response.data[0]
            if (self.hasPdfTrainingPlan && src.type === self.pdfTrainingPlanType) {
              self.openPdfTrainingPlanDialog(src)
            } else {
              Utils.setStorage('trainingPlan', {
                data: src,
                client: self.client,
              })
              self.$router.navigate.push({ path: '/home/trainingPlan' })
            }
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    openPdfTrainingPlanDialog: function (item, useBase) {
      this.pdfTrainingPlan = {
        id: !useBase ? item.id : null,
        name: item.name,
        details: item.details,
        start_date: item.start_date,
        end_date: item.end_date,
        current: useBase || item.id === this.trainingPlans[0].id ? true : false,
      }
      this.dialogPdfTrainingPlan = true
    },
    addTrainingPlan: function () {
      const self = this
      this.$isLoading(true)
      let notes = null
      let gender = null
      let place = null
      let level = null
      let totalTrains = null
      let duration = null
      if (this.autoFilters) {
        notes = this.getClientNotes()
        gender = this.client.gender
        if (notes && notes.training_plan_place) {
          const placeItem = this.trngPlanPlaces.find(function (it) {
            return it.id === notes.training_plan_place
          })
          if (placeItem) {
            place = placeItem.value
          }
        }
        if (notes && notes.training_plan_level) {
          const levelItem = this.trngPlanLevels.find(function (it) {
            return it.id === notes.training_plan_level
          })
          if (levelItem) {
            level = levelItem.value
          }
        }
        if (this.showFilterTotalTrains && notes && notes.training_plan_total_trains) {
          const totalTrainsItem = this.trngPlanTotalTrains.find(function (it) {
            return it.value === notes.training_plan_total_trains
          })
          if (totalTrainsItem) {
            totalTrains = totalTrainsItem.value
          }
        }
        if (this.showDuration && notes && notes.training_plan_duration) {
          const durationItem = this.trngPlanDurations.find(function (it) {
            return it.value === notes.training_plan_duration
          })
          if (durationItem) {
            duration = durationItem.value
          }
        }
      }
      if (!gender && this.autoFilterGender) {
        gender = this.client.gender
      }
      this.baseTrainingPlansFilters = {
        gender: gender,
        place: place,
        level: level,
        total_trains: totalTrains,
        duration: duration,
      }

      this.getBaseTrainingPlans(function (trainingPlans) {
        self.$isLoading(false)
        if (trainingPlans.length) {
          self.baseTrainingPlans = trainingPlans
          validateBaseTrainingPlanFiltered()
          self.setBaseTrainingPlansAdded()
          self.dialogNewTrainingPlan = true
        } else {
          self.newTrainingPlan()
        }
      })

      function validateBaseTrainingPlanFiltered() {
        if (self.autoFilters) {
          let baseTrainingPlans = JSON.parse(JSON.stringify(self.baseTrainingPlans))
          if (self.showPlaceAndGender) {
            if (self.baseTrainingPlansFilters.place) {
              baseTrainingPlans = baseTrainingPlans.filter(function (item) {
                return item.place === self.baseTrainingPlansFilters.place
              })
            }
            if (self.baseTrainingPlansFilters.gender) {
              baseTrainingPlans = baseTrainingPlans.filter(function (item) {
                return item.gender === self.baseTrainingPlansFilters.gender
              })
            }
          }
          if (self.showLevel && self.baseTrainingPlansFilters.level) {
            baseTrainingPlans = baseTrainingPlans.filter(function (item) {
              return item.level === self.baseTrainingPlansFilters.level
            })
          }
          if (!baseTrainingPlans.length) {
            self.baseTrainingPlansFilters.level = null
            self.baseTrainingPlansFilters.total_trains = null
            self.baseTrainingPlansFilters.duration = null
            return true
          }
          if (self.showFilterTotalTrains && self.baseTrainingPlansFilters.total_trains) {
            baseTrainingPlans = baseTrainingPlans.filter(function (item) {
              return item.days_count === parseInt(self.baseTrainingPlansFilters.total_trains)
            })
          }
          if (!baseTrainingPlans.length) {
            self.baseTrainingPlansFilters.total_trains = null
            self.baseTrainingPlansFilters.duration = null
            return true
          }
          if (self.showFilterTotalTrains && self.baseTrainingPlansFilters.duration) {
            baseTrainingPlans = baseTrainingPlans.filter(function (item) {
              return item.duration === self.baseTrainingPlansFilters.duration
            })
          }
          if (!baseTrainingPlans.length) {
            self.baseTrainingPlansFilters.duration = null
            return true
          }
        }
      }
    },
    getClientNotes: function () {
      let data = {}
      if (this.client) {
        try {
          const notes = JSON.parse(JSON.stringify(this.client.notes))
          data = JSON.parse(notes)
        } catch {}
      }
      return data
    },
    setBaseTrainingPlansAdded: function () {
      const self = this
      if (this.showBaseAddedLabel) {
        const added = []
        let current = null
        this.trainingPlans.forEach(function (item) {
          if (item.base_id && added.indexOf(item.base_id) === -1) {
            added.push(item.base_id)
          }
          if (self.planStatusDict[item.status] === 'active') {
            current = item.base_id
          }
        })
        this.baseTrainingPlanCurrent = current
        this.baseTrainingPlansAdded = added
      }
    },
    newTrainingPlan: function (item) {
      const self = this

      if (item && item.id) {
        this.$isLoading(true)
        Api.getTrainingPlan(
          {
            id: item.id,
          },
          function (response) {
            self.$isLoading(false)
            if (response.success) {
              confirm(response.data[0])
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          },
        )
      } else {
        confirm(item)
      }

      function confirm(trainingPlanBase) {
        if (self.hasPdfTrainingPlan && trainingPlanBase && trainingPlanBase.type === self.pdfTrainingPlanType) {
          self.dialogNewTrainingPlan = false
          self.openPdfTrainingPlanDialog(trainingPlanBase, true)
          return false
        }

        const data = {
          name: '',
          days: [],
        }

        if (trainingPlanBase) {
          data.place = trainingPlanBase.place
          data.gender = trainingPlanBase.gender
          data.level = trainingPlanBase.level
          data.name = trainingPlanBase.name
          data.days = trainingPlanBase.days
          data.details = trainingPlanBase.details
          data.base_id = trainingPlanBase.id
          data.image = trainingPlanBase.image
          data.proposal = trainingPlanBase.proposal
          data.duration = trainingPlanBase.duration
          if (self.hasTranslations) {
            self.hasTranslations.forEach((t) => {
              data['name_' + t] = trainingPlanBase['name_' + t]
              data['details_' + t] = trainingPlanBase['details_' + t]
            })
          }

          try {
            if (self.autoFilters) {
              const notes = self.getClientNotes()
              if (notes && notes.training_plan_place) {
                const placeItem = self.trngPlanPlaces.find(function (it) {
                  return it.id === notes.training_plan_place
                })
                const notesPlace = placeItem ? placeItem.value : null
                if (notesPlace !== self.baseTrainingPlansFilters.place && self.$refs.notes) {
                  const newTrainingPlanPlace = self.trngPlanPlaces.find(function (it) {
                    return it.value === self.baseTrainingPlansFilters.place
                  })
                  if (newTrainingPlanPlace) {
                    notes.training_plan_place = newTrainingPlanPlace.id
                    return self.$refs.notes.updateNotes(notes, () => {
                      Utils.setStorage('trainingPlan', {
                        data: data,
                        client: self.client,
                      })
                      self.$router.navigate.push({ path: '/home/trainingPlan' })
                    })
                  }
                }
              }
            }
          } catch {}
        }

        Utils.setStorage('trainingPlan', {
          data: data,
          client: self.client,
        })
        self.$router.navigate.push({ path: '/home/trainingPlan' })
      }
    },
    savePdfTrainingPlan: function (saveHistory) {
      const self = this
      const data = this.getPdfTrainingPlan()
      this.$isLoading(true)

      if (data.id && !saveHistory) {
        Api.editTrainingPlan(data, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.dialogPdfTrainingPlan = false
            self.getTrainingPlans()
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        })
      } else {
        delete data.id
        Api.newTrainingPlan(data, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.dialogPdfTrainingPlan = false
            self.getTrainingPlans()
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        })
      }
    },
    getPdfTrainingPlan: function () {
      const pdfTrainingPlan = {
        client_id: this.client.dbId,
        name: this.pdfTrainingPlan.name,
        details: this.pdfTrainingPlan.details,
        start_date: this.pdfTrainingPlan.start_date,
        end_date: this.pdfTrainingPlan.end_date,
        type: this.pdfTrainingPlanType,
      }

      if (this.pdfTrainingPlan && this.pdfTrainingPlan.id) {
        pdfTrainingPlan.id = this.pdfTrainingPlan.id
      }

      return pdfTrainingPlan
    },
    getBaseTrainingPlans: function (callback) {
      const self = this
      let trainingPlans = []
      Api.getTrainingPlans(
        {
          fast: true,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            trainingPlans = response.data
          }
          callback(trainingPlans)
        },
      )
    },
    loadPdfTrainingPlanPdf(e) {
      const self = this
      if (!e.target.files.length) {
        return false
      }
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        self.pdfTrainingPlan.details = e.target.result
      }
    },
    duplicateTrainingPlan(item) {
      const self = this

      this.$confirm(
        window.strings['want_duplicate_training_plan'] + (item ? ' "' + item.name + '"' : '') + '?',
        '',
        'info',
        Utils.getAlertOptions(true, false),
      )
        .then(() => {
          self.$isLoading(true)
          Api.getTrainingPlan(
            {
              client_id: self.client.dbId,
              id: item.id,
            },
            function (response) {
              if (response.success) {
                const trainingPlan = response.data[0]
                const trainingPlanToSave = JSON.parse(JSON.stringify(trainingPlan))
                delete trainingPlanToSave.id
                trainingPlanToSave.client_id = self.client.dbId
                trainingPlanToSave.start_date = moment().format('YYYY-MM-DD')
                if (self.defaultStartDate) {
                  trainingPlanToSave.start_date = moment()
                    [self.defaultStartDate.calc](self.defaultStartDate.value, self.defaultStartDate.type)
                    .format('YYYY-MM-DD')
                }
                trainingPlanToSave.end_date = moment().add(10, 'days').format('YYYY-MM-DD')
                if (self.defaultEndDate) {
                  trainingPlanToSave.end_date = moment()
                    [self.defaultEndDate.calc](self.defaultEndDate.value, self.defaultEndDate.type)
                    .format('YYYY-MM-DD')
                }
                Api.newTrainingPlan(trainingPlanToSave, function (response) {
                  self.$isLoading(false)
                  if (response.success) {
                    self.getTrainingPlans()
                  } else {
                    self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
                  }
                })
              } else {
                self.$isLoading(false)
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
              }
            },
          )
        })
        .catch(() => {})
    },
    openBaseTrainingPlanInfo: function (item) {
      const self = this
      this.$isLoading(true)
      Api.getTrainingPlan(
        {
          id: item.id,
        },
        function (response) {
          if (response.success) {
            const trainingPlan = response.data[0]
            if (self.workoutsDict) {
              open(trainingPlan)
            } else {
              self.getWorkouts(function () {
                open(trainingPlan)
              })
            }
          } else {
            self.$isLoading(false)
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )

      function open(trainingPlan) {
        self.$isLoading(false)
        const obj = {
          details: {},
          data: trainingPlan,
        }
        if (self.showPlaceAndGender) {
          obj.details.gender = self.trngPlanGenders.find(function (it) {
            return it.value === trainingPlan.gender
          }).label
          obj.details.place = self.trngPlanPlaces.find(function (it) {
            return it.value === trainingPlan.place
          }).label
        }
        if (self.showLevel) {
          obj.details.level = self.trngPlanLevels.find(function (it) {
            return it.value === trainingPlan.level
          }).label
        }
        self.baseTrainingPlanInfo = obj
        self.dialogBaseTrainingPlanInfo = true
      }
    },
    getWorkouts: function (callback) {
      const self = this
      Api.getWorkouts(
        {
          useCache: true,
        },
        function (response) {
          if (response.success) {
            const dict = {}
            for (let i = 0; i < response.data.length; i++) {
              dict[response.data[i].id] = response.data[i]
            }
            self.workoutsDict = dict
            callback()
          } else {
            self.$isLoading(false)
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    closeBaseTrainingPlanInfo: function () {
      this.dialogBaseTrainingPlanInfo = false
      this.baseTrainingPlanInfo = null
    },
    changePlanStatus(item, newStatus) {
      const self = this
      this.$isLoading(true)
      Api.getTrainingPlan(
        {
          client_id: this.client.dbId,
          id: item.id,
        },
        function (response) {
          if (response.success) {
            const planToSave = JSON.parse(JSON.stringify(response.data[0]))
            planToSave.client_id = self.client.dbId
            let needsUpdate = false
            switch (newStatus) {
              case 'active':
                planToSave.start_date = moment().format('YYYY-MM-DD')
                if (self.defaultStartDate) {
                  planToSave.start_date = moment()
                    [self.defaultStartDate.calc](self.defaultStartDate.value, self.defaultStartDate.type)
                    .format('YYYY-MM-DD')
                }
                planToSave.end_date = moment().add(100, 'years').format('YYYY-MM-DD')
                if (self.defaultEndDate) {
                  planToSave.end_date = moment()
                    [self.defaultEndDate.calc](self.defaultEndDate.value, self.defaultEndDate.type)
                    .format('YYYY-MM-DD')
                }
                needsUpdate = true
                break
              case 'finished':
                planToSave.end_date = Utils.getFormatDate()
                if (planToSave.start_date > planToSave.end_date) {
                  planToSave.start_date = planToSave.end_date
                }
                needsUpdate = true
                break
            }
            if (needsUpdate) {
              Api.editTrainingPlan(planToSave, function (response) {
                self.$isLoading(false)
                if (response.success) {
                  self.getTrainingPlans()
                } else {
                  self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
                }
              })
            }
          } else {
            self.$isLoading(false)
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    sortTrainingPlans(data) {
      const ids = []
      const clone = JSON.parse(JSON.stringify(this.trainingPlans))
      for (let i = 0; i < data.target.children.length; i++) {
        ids.push(parseInt(data.target.children[i].getAttribute('data-id')))
      }
      for (let i = 0; i < clone.length; i++) {
        clone[i].sort_order = ids.indexOf(clone[i].id) + 1
      }
      this.trainingPlansSorted = clone
      this.dragging = false
    },
    saveSortTrainingPlans() {
      const self = this
      this.$isLoading(true)
      update(0)

      function update(index) {
        if (!self.trainingPlansSorted[index]) {
          self.$isLoading(false)
          self.trainingPlansSorted = null
          self.getTrainingPlans()
          return true
        }
        Api.editTrainingPlan(
          {
            client_id: self.client.dbId,
            id: self.trainingPlansSorted[index].id,
            sort_order: self.trainingPlansSorted[index].sort_order,
            dont_redo: true,
          },
          function (response) {
            if (response.success) {
              update(index + 1)
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          },
        )
      }
    },
  },
}
</script>
